@import '~bloko/common/styles/media/_screen';

.loyalty-survey-modal-subheader {
    @media @screen-gt-xs {
        width: 470px;
    }
}

.step-header-title {
    white-space: pre-line;
}
